import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'any'
})
export class SessionStorageService {
    private storageScriptObservable: Subject<string> = new Subject<string>();

    public setScript(id: string): void {
        sessionStorage.setItem('script', id);
    }

    public getStorageScriptObservable(itemName: string): Subject<string> {
        this.subscribeToScriptChanges(itemName);
        return this.storageScriptObservable;
    }

    private subscribeToScriptChanges(itemName: string) {
        let handleStorageUpdate = () => {
            let scriptId: string | null = sessionStorage.getItem(itemName);
            if (scriptId) {
                this.storageScriptObservable.next(scriptId);
                sessionStorage.removeItem(itemName);
            }
        };
        window.removeEventListener('storage', handleStorageUpdate);
        window.addEventListener('storage', handleStorageUpdate);
    }
}
