type ScenarioState = {
    isLoaded: boolean,
    selected_id: string,
    isSaving: boolean,
    isSavingWarningPopup: boolean,
    hasChangesAfterRequest: boolean
};

export const initialScenarioState: ScenarioState = {
    isLoaded: false,
    selected_id: '',
    isSaving: false,
    isSavingWarningPopup: false,
    hasChangesAfterRequest: false
};
