import { patchState, signalStoreFeature, withMethods } from "@ngrx/signals";
import { addEntity, removeEntity, setAllEntities, setEntity, updateEntity } from "@ngrx/signals/entities";
import { inject } from "@angular/core";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { tapResponse } from "@ngrx/operators";
import { ScenarioResponse } from "../../api/models/scenario-response";
import { concatMap, distinctUntilChanged, exhaustMap, filter, mergeMap, pipe, tap } from "rxjs";
import { ApiService } from "../../api/services/api.service";
import { ScenarioUpdateRequest } from "../../api/models/scenario-update-request";
import { ScenarioCreateRequest } from "../../api/models/scenario-create-request";
import { SessionStorageService } from "../../services/session-storage/session-storage.service";
import { Router } from "@angular/router";
import { DataCacheDbController } from "../../pages/script/data-cache-db.controller";
import { TDataCacheDB } from "../../pages/script/data-cache-db.types";
import { PostMessageService } from "../../global-events/PostMessage/post-message.service";

export function withScriptsApi() {
    return signalStoreFeature(
        withMethods((store: any,
            apiService: ApiService = inject(ApiService),
            sessionStorageService = inject(SessionStorageService),
            postMessageService = inject(PostMessageService),
            router: Router = inject(Router)
        ) => ({
            loadScenarios: rxMethod<void>(
                pipe(
                    exhaustMap(() => {
                        let dataCacheDbController = new DataCacheDbController();
                        // dataCacheDbController.readDb('apiScenariosGet').then((obj: TDataCacheDB) => {
                        //     if (obj?.id && obj.id === 'apiScenariosGet') {
                        //         patchState(store, setAllEntities(JSON.parse(obj.text)));
                        //         patchState(store, { isLoaded: true });
                        //     }
                        // });
                        return apiService.apiScenariosGet().pipe(
                            tap({
                                next: (scenarios: ScenarioResponse[]) => {
                                    dataCacheDbController.writeDb(scenarios, 'apiScenariosGet');
                                    patchState(store, setAllEntities(scenarios));
                                    patchState(store, { isLoaded: true });
                                },
                                error: console.error,
                            })
                        );
                    })
                ),
            ),
            loadScenarioById: rxMethod<string>(
                pipe(filter((id: string) => !!id), exhaustMap((id) => {
                    return apiService.apiScenariosIdGet({
                        id
                    }).pipe(
                        tapResponse({
                            next: (scenario: ScenarioResponse) => {
                                patchState(store, setEntity(scenario));
                                patchState(store, { isLoaded: true })
                            },
                            error: console.error,
                        })
                    );
                }))
            ),
            createScenario: rxMethod<ScenarioCreateRequest>(
                pipe(filter((scenario: ScenarioCreateRequest) => !!scenario), exhaustMap((scenario: ScenarioCreateRequest) => {
                    if (!store.isSavingWarningPopup()) {
                        patchState(store, {isSaving: true});
                    }

                    scenario.name = scenario.name === '' ? 'Новый Скрипт' : scenario.name;
                    return apiService.apiScenariosPost({ body: scenario }).pipe(
                        tapResponse({
                            next: (scenario: ScenarioResponse) => {
                                patchState(store, updateEntity({id: scenario.id, changes: {...scenario}}));
                                store.isSavingWarningPopup()
                                    ? postMessageService.sendMessage(['close_modal_scenario_by_id'])
                                    : patchState(store, { isSaving: false, hasChangesAfterRequest: false });
                                sessionStorageService.setScript(scenario.id);
                                router.navigateByUrl(router.createUrlTree([router.url, scenario.id]));
                            },
                            error: console.error
                        })
                    )
                }))
            ),
            removeScenarioById: rxMethod<string>(
                pipe(
                    filter((id: string) => !!id && store.entityMap()[id]),
                    distinctUntilChanged(),
                    mergeMap((id) => {
                    return apiService.apiScenariosIdDelete({ id }).pipe(
                        tapResponse({
                            next: () => patchState(store, removeEntity(id)),
                            error: console.error
                        })
                    )
                }))
            ),
            copyScenarioById: rxMethod<string>(
                pipe(filter((id) => !!id && store.entityMap()[id]), concatMap((id) => {
                    let scenario = store.entityMap()[id];
                    return apiService.apiScenariosPost({
                        body: {
                            actions: scenario.actions,
                            description: scenario.description,
                            enabled: scenario.enabled,
                            event_id: scenario.event_id,
                            name: scenario.name,
                            scenario_group_id: scenario.scenario_group_id
                        }
                    }).pipe(
                        tapResponse({
                            next: (scenario: ScenarioResponse) => patchState(store, addEntity(scenario)),
                            error: console.error
                        })
                    );
                }))
            ),
            updateScenarioById: rxMethod<{ id: string, updateScenario: ScenarioUpdateRequest }>(
                pipe(filter((info) => !!info.id && store.entityMap()[info.id]), mergeMap((info) => {
                    if (!store.isSavingWarningPopup()) {
                        patchState(store, {isSaving: true});
                    }

                    info.updateScenario.name = info.updateScenario.name === '' ? 'Новый Скрипт' : info.updateScenario.name;
                    info.updateScenario.scenario_group_id = info.updateScenario.scenario_group_id === 'general' ? null : info.updateScenario.scenario_group_id;
                    return apiService.apiScenariosIdPatch({ id: info.id, body: info.updateScenario }).pipe(
                        tapResponse({
                            next: (scenario: ScenarioResponse) => {
                                patchState(store, updateEntity({id: scenario.id, changes: {...scenario}}));
                                store.isSavingWarningPopup()
                                    ? postMessageService.sendMessage(['close_modal_scenario_by_id'])
                                    : patchState(store, { isSaving: false, hasChangesAfterRequest: false });
                                sessionStorageService.setScript(scenario.id);
                            },
                            error: console.error
                        })
                    )
                }))
            ),
            patchPropertyById: rxMethod<{ id: string, property: keyof ScenarioUpdateRequest, value: ScenarioUpdateRequest[keyof ScenarioUpdateRequest] }>(
                pipe(filter((info) => !!info.id && store.entityMap()[info.id]), mergeMap((info) => {
                     let transformPropertyValue = (property: keyof ScenarioUpdateRequest, value: ScenarioUpdateRequest[keyof ScenarioUpdateRequest]) => {
                        if (property === 'scenario_group_id' && value === 'general')  {
                            value = null;
                        }
                        return value;
                    };

                    return apiService.apiScenariosIdPatch({
                        id: info.id,
                        body: {
                            [info.property]: transformPropertyValue(info.property, info.value)
                        }
                    }).pipe(
                        tapResponse({
                            next: (scenario: ScenarioResponse) => patchState(store, updateEntity({ id: info.id, changes: { ...scenario }})),
                            error: console.error
                        })
                    )
                }))
            )
        })),
    )
}
